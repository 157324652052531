import { IpifyData } from './types/IpifyData';
import { BOT_IP_LOG } from './data/bot-ip-log';
import { checkIsIPNotAllowed } from './util/checkIsIPNotAllowed';

void fetch('https://api.ipify.org?format=json')
  .then(res => res.json())
  .then((data: IpifyData) => {
    if (checkIsIPNotAllowed(data.ip, BOT_IP_LOG) && window.location.pathname !== '/not-allowed') {
      window.location.assign('/not-allowed');
    }
  });
